











































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import ReportModule from './ReportModule.vue'
import StModule from './StModule.vue'
import moment from 'moment'

// import MonthlySVG from '@/assets/svg/monthlyReportBg.svg'

@Component({
  components: {
    ReportModule,
    StModule,
  },
})
export default class Report extends Vue {
  @Prop() public readonly data!: any
  @Prop() public readonly loaded!: boolean

  private imgError: boolean = false
  private Vue = Vue
  private moment = moment

  private attainmentGrades: any = [
    {
      key: 1,
      grade: 'A+',
      descriptorEn: 'Outstanding(to be awarded only rarely)',
      descriptor: '（优秀）只给少数的人',
    },
    {
      key: 2,
      grade: 'A',
      descriptorEn: 'Very good',
      descriptor: '很好',
    },
    {
      key: 3,
      grade: 'B+',
      descriptorEn: 'Good',
      descriptor: '好',
    },
    {
      key: 4,
      grade: 'B',
      descriptorEn: 'Meeting requirement',
      descriptor: '达标',
    },
    {
      key: 5,
      grade: 'C',
      descriptorEn: 'Some improvement needed',
      descriptor: '尚需提高',
    },
    {
      key: 6,
      grade: 'D',
      descriptorEn: 'Causing concern (awarded only following consultation with tutor)',
      descriptor: '需引起注意（该等级给予之前应先咨询导师）',
    },
  ]
  private effortGrades: any = [
    {
      key: 1,
      grade: '1',
      descriptorEn: 'Very good',
      descriptor: '很好',
    },
    {
      key: 2,
      grade: '2',
      descriptorEn: 'Good',
      descriptor: '好',
    },
    {
      key: 3,
      grade: '3',
      descriptorEn: 'Capable of more',
      descriptor: '有待进步',
    },
    {
      key: 4,
      grade: '4',
      descriptorEn: 'Causing concern (awarded only following consultation with tutor)',
      descriptor: '需引起注意（该等级给予之前应先咨询导师）',
    },
  ]

  private get reportDate(): any {
    const str = moment(this.data.month)
      .locale('zh-cn')
      .format('YYYY年M月')
    return str
  }

  private get reportDateEn(): any {
    const str = moment(this.data.month).locale('en')
    return str.format('MMMM YYYY')
  }

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'courseName',
        key: 'courseName',
        title: 'Subject Category 科目',
        width: 200,
      },
      this.data.gradePeriod.ea && [
        {
          dataIndex: 'effort',
          key: 'effort',
          title: 'Effort 努力程度',
          width: 200,
        },
        {
          dataIndex: 'attainment',
          key: 'attainment',
          title: 'Attainment 取得成绩',
          width: 200,
        },
      ],
      {
        dataIndex: 'teachers',
        key: 'teachers',
        title: 'Staff 教师',
        width: 200,
      },
    ]
      .filter(item => item)
      .flat()
  }

  private get AColumnsZh(): Array<any> {
    return [
      {
        dataIndex: 'grade',
        key: 'grade',
        title: '等级',
        width: 200,
      },
      {
        dataIndex: 'descriptor',
        key: 'descriptor',
        title: '等级描述',
      },
    ]
  }
  private get AColumnsEn(): Array<any> {
    return [
      {
        dataIndex: 'grade',
        key: 'grade',
        title: 'Grade',
        width: 200,
      },
      {
        dataIndex: 'descriptorEn',
        key: 'descriptor',
        title: 'Descriptor',
      },
    ]
  }
}
